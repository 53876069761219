import React, { useEffect, useState } from 'react'
import { Box, Card, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { EventBase } from 'client'
import { gColor, gSx } from 'styles/Theme'
import ClientEventDialog from 'components/client/ClientCardForEventDialog'
import EditIcon from '@mui/icons-material/Edit'
import { CrmClientBadge } from 'components/client/CrmClientBadge'

interface Props {
  event?: EventBase
  disabled?: boolean
}

export default function RedtailCrmClientCard({ event, disabled }: Props) {
  const hasIdentity = event?.client && (event?.client.email || event?.client?.redtail_id)
  const [showAddClientDialog, setShowAddClientDialog] = useState(false)

  return (
    <Card>
      <Grid container >
        <Grid xs={2}>
          <Typography variant='h5'>Client</Typography>
        </Grid>
        <Grid xs={7}>
          <Typography color={gColor.green500}>
            {event?.client?.first_name ?? ''} {event?.client?.last_name ?? ''}
          </Typography>
          <Typography color={gColor.green500}>{event?.client?.email ?? ''}</Typography>

          {!hasIdentity &&
            <>
              <Box sx={gSx.Row}>
                <Typography sx={{ color: 'red', cursor: 'pointer', fontWeight: 'bold' }}>
                  Add a contact
                </Typography>
                <IconButton
                  aria-label='edit'
                  onClick={() => setShowAddClientDialog(true)}
                  disabled={disabled}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Box>

              <Typography >
                The Email should match your existing CRM contact, or Finmate will automatically create a new CRM contact for you.
              </Typography>
            </>
          }
          <ClientEventDialog
            event={event}
            open={showAddClientDialog}
            onClose={() => setShowAddClientDialog(false)}
          />
        </Grid>
        <Grid xs={3} sx={{ alignContent: 'center' }}>
          <CrmClientBadge client={event?.client} />
        </Grid>
      </Grid>
    </Card>
  )
}
