import React, { useRef, useEffect, useState } from 'react'
import { Api, auth, config } from '../../service/api'
import { Box, SxProps, Card, Typography, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import ReactPlayer from 'react-player'
import { useQuery } from '@tanstack/react-query'
import { useStore } from '../../hooks/useStore'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'

interface QueryError {
  message: string;
  description: string;
  statusCode: string | number;
  status: string | number;
}

interface Props {
  eventId: string
  width: number
  isPublic?: boolean
}

export default observer(VideoCard)
function VideoCard({ eventId, isPublic, width }: Props) {
  const playerRef = useRef(null)
  const store = useStore()
  const seekSeconds = store.User.getSeekSeconds()
  const [videoUrl, setVideoUrl] = useState('')

  const { data, error, isLoading } = useQuery({
    queryKey: ['getEventVideo' + eventId],
    refetchOnWindowFocus: false,
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs, // retry max 7 secs
  })

  async function runQuery() {

    const api = await Api()
    const res = isPublic
      ? await api.public.getPublicEventVideo(eventId)
      : await api.events.getEventVideo(eventId)
    return res
  }

  useEffect(() => {
    // data.bucket_filename
    if (data && data.bucket_filename && data.bucket_filename != videoUrl) {
      setVideoUrl(data.bucket_filename)
    }
  }, [data?.bucket_filename])

  if (error) {
    const queryError = error as QueryError
    if (queryError.status === 410) {
      return (
        <Card sx={sxCard}>
          <Box sx={sxProcessing}>
            <Typography variant="h6" color="grey">Video Expired</Typography>
            <Box sx={{ padding: '2vh' }} />
          </Box>
        </Card>
      )
    }
  }

  const height = (width * 9) / 16
  const sxPlayerWrapper: SxProps = {
    position: 'relative',
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: '10px' /* Add your desired border radius value here */,
    overflow: 'hidden',
  }

  if (playerRef.current) {
    // @ts-expect-error seekto never
    playerRef.current.seekTo(seekSeconds, 'seconds')
  }

  if (isLoading || !data) {
    return (
      <Card sx={sxCard}>
        <Box sx={sxProcessing}>

          <Typography>Video Processing</Typography>
          <Typography>Please check in a few minutes</Typography>
          <CircularProgress />

          <Box sx={{ padding: '2vh' }} />

        </Box>
      </Card>
    )
  }
  function onVideoProgress(event: any) {
    store.User.setTranscriptSeekSeconds(event.playedSeconds)
  }
  return (
    <Box sx={sxPlayerWrapper}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        controls
        light={data.thumbnail_filename}
        width={`${width}px`}
        height={`${height}px`}
        volume={0.5}
        onProgress={onVideoProgress}
      />
    </Box>
  )

}

const sxCard: SxProps = {
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const sxProcessing: SxProps = {
  height: '25vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}
