import React from "react"
import { useQuery } from "@tanstack/react-query"
import { getUserConfig } from "service"
import { getCalendarEvents, isZoomScheduled } from "service/calendarApi"
import ZoomCardForRecorder from "./ZoomCardForRecorder"

const ZoomSetupAtHome = () => {

  const { data: schedule } = useQuery({
    queryKey: ['getCalendarEvents'], queryFn: getCalendarEvents,
  })

  const { data: userConfig } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })

  const hasZoomScheduled = isZoomScheduled(schedule?.events)
  const isZoomConnected = !!userConfig?.recall_zoom_oauth_id

  if (hasZoomScheduled && userConfig && !isZoomConnected)
    return <ZoomCardForRecorder />

  return null
}

export default ZoomSetupAtHome
