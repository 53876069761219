import React, { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Alert, Box, Button, Dialog, IconButton, Menu, MenuItem, Snackbar, SxProps, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { gSx } from 'styles/Theme'
import { ClientDetail } from 'client'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { deleteClient } from 'service/clientApi'
import { useQueryClient } from '@tanstack/react-query'
import { sleep } from 'utils'

interface Props {
  data?: ClientDetail
  disabled?: boolean
}

export default function ClientDropdownMenu({ data, disabled }: Props) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const queryClient = useQueryClient()

  const [openDialog, setOpenDialog] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const currentUrl = window.location.href
  const isPublicEvent = currentUrl.includes('public')

  function onMenuDeleteOpen() {
    setOpenDialog(true)
    setAnchorEl(null)
  }

  async function onDeleteEvent() {
    setBusy(true)
    setErr(undefined)

    const res = await deleteClient(data?.id)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenSuccessSnack(true)
      queryClient.invalidateQueries({ queryKey: ['getClientsIndex'] })
      setOpenDialog(false)
      navigate(Path.Clients)
    }
    setBusy(false)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onMenuDeleteOpen}>Delete Client</MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <Box sx={sxTop}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            Confirm Delete Client
          </Typography>
          <IconButton aria-label='close' onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography sx={{ color: 'tomato', padding: 3 }}>
          Warning: This Action is Irreversible
        </Typography>

        <Box sx={gSx.RowBetween}>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            aria-label='Keep'
            sx={{ margin: '10px' }}
          >
            Keep
          </Button>

          <LoadingButton
            sx={{ margin: '10px' }}
            onClick={onDeleteEvent}
            color="error"
            loading={busy}
            autoFocus
            aria-label='Delete'
            disabled={isPublicEvent}
            variant='contained'
          >
            Delete
          </LoadingButton>
        </Box>

        {err && <Typography color={'tomato'} sx={{ padding: 2 }}>
          {err}
        </Typography>
        }
      </Dialog >

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Client has been deleted
        </Alert>
      </Snackbar>
    </>
  )
}

const sxTop: SxProps = {
  display: 'flex',
  alignItems: 'center',
  paddingRight: '4px',
  paddingLeft: '24px',
  paddingTop: '8px'
}