import React, { HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  DialogActions,
  Divider,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  ListItem,
  ListItemText,
} from '@mui/material'
import { ClientIndex, EventBase } from '../../client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Path } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { logInfo } from '../../log'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { getClientsIndex, getRecentClients, setClientEvent } from 'service/clientApi'
import { gSx } from 'styles/Theme'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import ImportContactsListButtons from 'components/integrations/ImportContactsListButtons'
import { createFuzzySearch } from 'utils/fuzzySearch'
import { CrmClientBadge } from './CrmClientBadge'

interface Props {
  event?: EventBase

  setIsAddStep: (isAdd: boolean) => void
  onClose: () => void
  onSuccessSnack: () => void
}

const LIMIT_RESULTS = 100
export default function ClientForEventExisting({ event, setIsAddStep, onClose, onSuccessSnack }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const eventId = event?.id ?? ''
  const recentRef = useRef<ClientIndex[]>([])
  const searchRef = useRef<((query: string) => ClientIndex[]) | undefined>()
  const [dropdown, setDropdown] = useState<ClientIndex[]>([])
  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState<ClientIndex | undefined>(event?.client)
  const [busy, setBusy] = useState(false)
  const [expandSpace, setExpandSpace] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getClientsIndex'], queryFn: getClientsIndex, enabled: false
  })
  useEffect(() => { // use cache first, else fetch
    const cachedData = queryClient.getQueryData(['getClientsIndex'])
    if (!cachedData) refetch()
  }, [queryClient, refetch])

  useEffect(() => {
    if (data && data.length > 0) {
      recentRef.current = getRecentClients(LIMIT_RESULTS, data)
      setDropdown(recentRef.current)
      searchRef.current = createFuzzySearch(data)
    }
  }, [data])

  const onInputChange = (event: SyntheticEvent, value: string) => {
    setInputValue(value)
    if (searchRef.current && value.length > 0) {
      const searchResults = searchRef.current(value)
      setDropdown(searchResults.slice(0, LIMIT_RESULTS))
    } else {
      setDropdown(recentRef.current)
    }
  }

  async function onSave() {
    if (!selected)
      return
    setBusy(true)
    logInfo('Event, client details, set client', { eventId, clientId: selected?.id })

    await setClientEvent(eventId, selected?.id)
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] }),
      queryClient.invalidateQueries({ queryKey: ['getSpeakersAndAssignees', eventId] })
    ])

    onSuccessSnack()
    setBusy(false)
    onClose()
    setBusy(false)
  }

  return (
    <>
      <Box sx={gSx.RowBetween}>
        <DialogTitle>Set the Contact for this Meeting</DialogTitle>
        <IconButton
          sx={{ alignSelf: 'flex-start' }}
          aria-label='close'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ paddingY: 1, marginY: 0 }}>

        {isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={200} />
          : (
            <>
              <Autocomplete
                disablePortal
                options={dropdown}
                inputValue={inputValue}
                onInputChange={onInputChange}
                getOptionLabel={optionLabel}
                renderOption={renderOption}
                renderInput={(params) => <TextField {...params} label='Existing Contacts' />}
                value={selected}
                onChange={(event: any, newValue) => {
                  // event.preventDefault()
                  setSelected(newValue ?? undefined)
                }}
                onOpen={() => setExpandSpace(true)}
                onClose={() => setExpandSpace(false)}
                disabled={!data || isLoading}
              />

              <Box sx={gSx.RowBetween}>
                <Button
                  onClick={() => navigate(`${Path.ClientDetail}/${selected?.id}`)}
                  disabled={isLoading}
                >
                  {selected
                    ? `Edit Contact`
                    : <Box sx={{ padding: 1.2 }} />
                  }
                </Button>
                <Typography variant='caption'>{`displays top ${LIMIT_RESULTS} results`}</Typography>
              </Box>
            </>
          )
        }

        {expandSpace && <Box sx={{ paddingY: 14 }} />}

      </DialogContent>

      <DialogActions sx={{ paddingY: 0, marginY: 0 }}>
        <LoadingButton
          variant='contained'
          onClick={onSave}
          disabled={selected === null}
          sx={{ width: 100 }}
          loading={busy}
        >
          Save
        </LoadingButton>
      </DialogActions>

      <DialogContent >

        <Box sx={gSx.Row}>
          <Divider sx={{ width: '46%' }} />
          <Typography sx={{ width: '8%', textAlign: 'center' }}>OR</Typography>
          <Divider sx={{ width: '46%' }} />
        </Box>

        <Box sx={{ padding: '1vh' }} />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Button
            variant='outlined'
            onClick={() => setIsAddStep(true)}
            startIcon={<PersonAddIcon />}
          >
            Add a New Contact
          </Button>

          <ImportContactsListButtons />
        </Box>
      </DialogContent>
    </>
  )
}

function optionLabel(option: ClientIndex): string {
  if (option.first_name)
    return `${option.first_name ?? ''} ${option.last_name ?? ''}, ${option.email}`
  return `${option.email}`
}

function renderOption(props: HTMLAttributes<HTMLLIElement>, option: ClientIndex) {
  return (
    <ListItem {...props} key={option.id}>
      <ListItemText primary={optionLabel(option)} />
      <CrmClientBadge client={option} />
    </ListItem>
  )
}
