import React, { useState } from 'react'
import {
  ButtonGroup,
  Button,
  Tooltip,
  Box,
  TextField,
} from '@mui/material'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import TitleIcon from '@mui/icons-material/Title'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import { Editor } from '@tiptap/react'

interface MenuProps {
  editor?: Editor | null
}

export const EditorMenuBar = ({ editor }: MenuProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("Speaker 0")
  const [replaceTerm, setReplaceTerm] = useState<string>("")

  if (!editor) {
    return null
  }

  function handleReplaceAll() {
    editor?.commands.setSearchTerm(searchTerm)
    editor?.commands.setReplaceTerm(replaceTerm)
    editor?.commands.replaceAll()
    setSearchTerm("")
    setReplaceTerm("")
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <ButtonGroup size="small" aria-label="small button group">
        <Tooltip title="Header">
          <Button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} variant={editor.isActive('heading', { level: 3 }) ? "contained" : "outlined"} ><TitleIcon /></Button></Tooltip>
        <Tooltip title="Bullet List">
          <Button onClick={() => editor.chain().focus().toggleBulletList().run()} variant={editor.isActive('bulletList') ? "contained" : "outlined"} ><FormatListBulletedIcon /></Button></Tooltip>
        <Tooltip title="Ordered List">
          <Button onClick={() => editor.chain().focus().toggleOrderedList().run()} variant={editor.isActive('orderedList') ? "contained" : "outlined"} ><FormatListNumberedIcon></FormatListNumberedIcon></Button></Tooltip>
        <Tooltip title="Bold">
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            variant={editor.isActive('bold') ? "contained" : "outlined"}
          >
            <FormatBoldIcon />
          </Button></Tooltip>
        <Tooltip title="Italic">
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            variant={editor.isActive('italic') ? "contained" : "outlined"}
          >
            <FormatItalicIcon />
          </Button></Tooltip>
      </ButtonGroup>
      <ButtonGroup sx={{ paddingX: '5px' }} size="small" aria-label="small button group">
        <Tooltip title="Undo">
          <Button
            onClick={() => editor.chain().focus().undo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .undo()
                .run()
            }
          >
            <UndoIcon />
          </Button></Tooltip>
        <Tooltip title="Redo">
          <Button
            onClick={() => editor.chain().focus().redo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .redo()
                .run()
            }
          >
            <RedoIcon />
          </Button></Tooltip>
      </ButtonGroup>
      <Box display="flex" alignItems="center" gap={1}>
        <TextField
          size="small"
          label="Text you want to change"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={(event) => event.target.select()}
        />
        <TextField
          size="small"
          label="Replace with"
          variant="outlined"
          value={replaceTerm}
          onChange={(e) => setReplaceTerm(e.target.value)}
          onFocus={(event) => event.target.select()}
        />
        <Button size="small" disabled={replaceTerm.length == 0} variant="contained" color="primary" onClick={handleReplaceAll}>
          Replace All
        </Button>
      </Box>
    </Box>
  )
}