import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { Media } from '../../service/media'
import { Card, Typography, useMediaQuery } from '@mui/material'
import { gSx } from 'styles/Theme'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

type Testimonials = {
  message: string;
  name: string;
  position: string;
  profileUrl: string
};

const testimonials: Testimonials[] = [
  {
    message: `"This product is going to CHANGE ADVISORS' LIVES! I am always on the hunt for what will make running a financial planning practice more efficient and this is the holy grail!"`,
    name: 'Libby Griewe',
    position: 'The Efficient Advisor Podcast',
    profileUrl: Media.testimonial_libby_griewe
  },
  {
    message: `"I was on the search for a transcription tool that would help us record meetings and improve our note-taking process. I did not expect to be blown away by the AI capabilities of Finmate! Not only do we receive the full meeting transcription, but most importantly we receive a detailed summary with the most critical information in an easy to digest format. This is going to save us a lot of time and energy!"`,
    name: 'Corey Cyr',
    position: 'Ideal Retirement Solutions',
    profileUrl: Media.testimonial_corey_cyr
  },
  {
    message: `“FinMate AI is a total game changer. This will greatly help improve the efficiency of offices and put more time back in the advisor’s schedules to do what they love- helping clients”`,
    name: 'Zac Daulton',
    position: 'J.W. Cole',
    profileUrl: Media.testimonial_zac_daulton
  },
  {
    message: `"FinMate AI is a revolutionary tool that I recommend to every financial advisor I work with. This AI-driven solution excels at summarizing meeting notes, significantly enhancing efficiency and ensuring no important details are overlooked. Its seamless integrations with all top CRMs really make FinMate AI stand out as a top tool to incorporate into any tech stack! Its ease of use and effectiveness in making meetings more efficient make it a must-have for advisors looking to optimize their workflow, streamline operations, and improve productivity."`,
    name: 'Carly Kelly',
    position: 'Signal Advisors',
    profileUrl: Media.testimonial_carly_kelly
  },
  {
    message: `"FinMate eliminates the need to take notes during discovery meetings. It frees up the time it takes to organize your notes after the session and eliminates distracted multitasking during meetings...Successful advisors understand the importance of having an emotional connection with prospects and clients. FinMate is a welcome addition to the tools you can use because it eliminates the need to take notes in meetings where recording information is critical."
    https://www.advisorperspectives.com/articles/2023/08/22/insurance-ai-tool-radically-alters-meetings-dan-solin`,
    name: 'Dan Solin',
    position: 'Solin Consulting',
    profileUrl: Media.testimonial_dan_solin
  },
  {
    message: `"Focus on your client instead of taking notes.No need to spend hours organizing notes after your meetings.No need to have an assistant in the meeting to take notes.FinMate AI takes detailed and organized notes for you... with an AI model designed for the specific meeting type that it is. And in case you're wondering... they have a phone app for recording in-person meetings."`,
    name: 'Pro Advisor Suite',
    position: 'Tech Stack for Financial Advisors',
    profileUrl: Media.testimonial_joe_moss
  },
]

export default function SignupCarousel() {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = testimonials.length
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ maxWidth: isSmallScreen ? 300 : 500 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={500000}
      >
        {testimonials.map((t, index) => (
          <div key={t.name}>
            {Math.abs(activeStep - index) <= 2 ? (

              <Card sx={{ padding: 3, minHeight: 300, alignContent: 'center' }}>

                <Typography sx={{ fontStyle: 'italic' }}>
                  {t.message}
                </Typography>

                <Box sx={{ padding: 1 }} />

                <Box sx={gSx.Row}>
                  <Box
                    component="img"
                    src={t.profileUrl}
                    sx={{ width: 40, marginRight: 2 }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {t.name}
                    </Typography>

                    <Typography>
                      {t.position}
                    </Typography>
                  </Box>
                </Box>

              </Card>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <Box sx={gSx.CenterVertical}>
        <MobileStepper
          sx={{ backgroundColor: 'transparent', width: '300px' }}
          steps={maxSteps}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === 'rtl'
                ? <KeyboardArrowLeft />
                : <KeyboardArrowRight />
              }
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl'
                ? <KeyboardArrowRight />
                : <KeyboardArrowLeft />
              }
            </Button>
          }
        />
      </Box>
    </Box>
  )
}
