import React, { ComponentType, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { Box, SxProps } from '@mui/material'
import './index.css'
import { auth } from 'service/api'
import { QueryClient, useQuery } from '@tanstack/react-query'

import HomeScreen from './screens/HomeScreen'
import EventScreen from './screens/EventScreen'
import CalendarScreen from './screens/CalendarScreen'
import SignUpScreen from './screens/auth/SignUpScreen'
import SignInScreen from './screens/auth/SignInScreen'
import PublicEventScreen from './screens/PublicEventScreen'
import ClientsScreen from './screens/clients/ClientsScreen'
import ForgotPassword from './components/auth/ForgotPasswordScreen'
import AdminTranscriptJson from './screens/admin/AdminTranscriptJson'
import ClientDetailScreen from './screens/clients/ClientDetailScreen'
import AuthRecallMSCallback from './screens/auth/AuthRecallMSCallback'
import AuthRecallGoogleCallback from './screens/auth/AuthRecallGoogleCallback'
import PaySuccessScreen from './screens/settings/PaySuccessScreen'
import NavDrawer from './components/nav/NavDrawer'
import BlockedPendingDemoScreen from './screens/auth/BlockedPendingDemoScreen'
import ZoomCallback from './screens/auth/ZoomCallback'
import LoadingScreen from 'screens/auth/LoadingScreen'
import SignupAffiliation from 'screens/auth/SignupAffiliation'
import AuthWealthboxCallback from 'screens/auth/AuthWealthboxCallback'
import RecorderSettingsScreen from 'screens/settings/RecorderSettingsScreen'
import OrgScreen from 'screens/settings/OrgScreen'
import RedtailSettings from 'screens/settings/RedtailSettingsScreen'
import WealthboxSettings from 'screens/settings/WealthboxSettingsScreen'
import BillingScreen from './screens/settings/BillingScreen'
import ZoomSettingsScreen from 'screens/settings/ZoomSettingsScreen'
import IntegrationsScreen from 'screens/settings/IntegrationsScreen'
import SettingsScreen from 'screens/settings/SettingsScreen'
import CalendarAuthScreen from './screens/settings/CalendarAuthScreen'
import ReceivedInviteScreen from 'screens/settings/ReceivedInviteScreen'
import BlockedInactiveScreen from 'screens/auth/BlockInactiveScreen'
import SalesforceSettingsScreen from 'screens/settings/SalesforceSettingsScreen'
import AuthSalesforceCallback from 'screens/auth/AuthSalesforceCallback'
import AuthSalesforceCallbackLocalhost from 'screens/auth/AuthSalesforceCallbackLocalhost'
import PractifiSettingsScreen from 'screens/settings/PractifiSettingsScreen'
import Xlr8SettingsScreen from 'screens/settings/Xlr8SettingsScreen'
import TemplatesScreen from 'screens/templates/TemplatesScreen'
import { getUser } from 'service'
import WebRecorderPopUpScreen from 'components/recorder/WebRecorderScreen'
import TawkToHelp from './components/help/TawkToHelp'

export const Path = {
  Loading: '/',
  SignUp: '/signup',
  SignIn: '/signin',
  SignUpAffiliation: '/hi',

  ReceivedInvite: '/invited',

  AuthAction: '/auth/action',
  AuthRecallGoogleCallback: '/auth/google_oauth_callback', // for recall
  AuthRecallMicrosoftCallback: '/auth/ms_oauth_callback', // for recall
  AuthWealthboxCallback: '/auth/wealthbox/callback',
  AuthSalesforceCallback: '/auth/salesforce/callback',
  AuthSalesforceCallbackLocalhost: '/auth/salesforce/callback/localhost',
  ZoomCallback: '/zoom/callback',
  ForgotPassword: '/auth/forgotpassword',
  BlockedPendingDemo: '/signup/demo',
  BlockInactive: '/auth/inactive',

  Home: '/home',
  Calendar: '/calendar',
  Event: '/event',
  Clients: '/clients',
  ClientDetail: '/clients/detail',
  AddClient: '/clients/add',
  WebRecorder: '/web_recorder',

  Settings: '/settings',
  RecorderSettings: '/settings/recorder',
  Organization: '/settings/organization',
  Billing: '/settings/billing',
  CalendarAuth: '/settings/calendar_auth',

  Integrations: '/integrations',
  ZoomSettings: '/integrations/zoom',
  RedtailSettings: '/integrations/redtail',
  WealthboxSettings: '/integrations/wealthbox',
  SalesforceSettings: '/integrations/salesforce',
  PractifiSettings: '/integrations/practifi',
  Xlr8Settings: '/integrations/xlr8',

  Pay: '/pay',
  PaySuccess: '/pay/success',
  PayCancelled: '/pay/cancelled',

  AdminTranscriptJson: '/admin/transcriptjson',
  PublicEvent: '/public/events',

  Templates: '/templates',
  // CustomizeFollowupEmail: '/customize/followupemail',

}

export const drawerWidth = 180

export default observer(RouteList)
function RouteList() {
  const [fbUser, setfbUser] = useState(auth.currentUser)

  useEffect(() => {
    getAuth().onAuthStateChanged(u => {
      setfbUser(u)
      if (u)
        refetch()
    })
  }, [])

  // Note: if using useQuery here, it requires "refetchOnWindowFocus" = False,  b/c  it refreshes entire app. 
  // ie. manual file uploads on the dashboard screen. click outside the browser to unfocus the browser, and click the browser again, will interrupt upload.
  const { data: user, refetch } = useQuery({
    queryKey: ['getUser'],
    queryFn: async () => await getUser(),
    refetchOnWindowFocus: false, // required
    enabled: false, // run only if signed in
  })

  interface Props {
    Target: ComponentType
  }
  function Wrapper({ Target }: Props) {
    if (window.location.href.includes('/hi/'))
      return <SignupAffiliation />
    if (fbUser == null)
      return <SignInScreen />
    if (user?.blocked_pending_demo)
      return <BlockedPendingDemoScreen />
    return <Target />
  }

  return (
    <BrowserRouter>
      <Box sx={sxContainer}>
        <TawkToHelp />
        <NavDrawer
          drawerWidth={drawerWidth}
        />
        <Box
          component='main'
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Routes>
            <Route
              path='/'
              element={<LoadingScreen />}
            />
            <Route
              path={Path.SignUp}
              element={<SignUpScreen />}
            />
            <Route
              path={Path.SignIn}
              element={<SignInScreen />}
            />
            <Route
              path={`${Path.SignUpAffiliation}/:id`}
              element={<Wrapper Target={SignupAffiliation} />}
            />
            <Route
              path={`${Path.ClientDetail}/:id`}
              element={<Wrapper Target={ClientDetailScreen} />}
            />
            <Route
              path={`${Path.AuthRecallGoogleCallback}/*`}
              element={<AuthRecallGoogleCallback />}
            />
            <Route
              path={`${Path.AuthRecallMicrosoftCallback}/*`}
              element={<AuthRecallMSCallback />}
            />
            <Route
              path={`${Path.AuthWealthboxCallback}/*`}
              element={<AuthWealthboxCallback />}
            />
            <Route
              path={`${Path.AuthSalesforceCallback}/*`}
              element={<AuthSalesforceCallback />}
            />
            <Route
              path={`${Path.AuthSalesforceCallbackLocalhost}/*`}
              element={<AuthSalesforceCallbackLocalhost />}
            />
            <Route
              path={Path.ForgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={Path.BlockedPendingDemo}
              element={<BlockedPendingDemoScreen />}
            />
            <Route
              path={Path.BlockInactive}
              element={<BlockedInactiveScreen />}
            />

            <Route
              path={Path.CalendarAuth}
              element={<Wrapper Target={CalendarAuthScreen} />}
            />
            <Route
              path={Path.Home}
              element={<Wrapper Target={HomeScreen} />}
            />
            <Route
              path={`${Path.Event}/:id`}
              element={<Wrapper Target={EventScreen} />}
            />

            <Route
              path={Path.Calendar}
              element={<Wrapper Target={CalendarScreen} />}
            />
            <Route
              path={Path.Clients}
              element={<Wrapper Target={ClientsScreen} />}
            />
            <Route
              path={`${Path.ClientDetail}/:id`}
              element={<Wrapper Target={ClientDetailScreen} />}
            />

            <Route
              path={Path.WebRecorder}
              element={<Wrapper Target={WebRecorderPopUpScreen} />}
            />

            <Route
              path={Path.Settings}
              element={<Wrapper Target={SettingsScreen} />}
            />

            <Route
              path={Path.RecorderSettings}
              element={<Wrapper Target={RecorderSettingsScreen} />}
            />

            <Route
              path={Path.Organization}
              element={<Wrapper Target={OrgScreen} />}
            />

            <Route
              path={Path.Billing}
              element={<Wrapper Target={BillingScreen} />}
            />

            <Route
              path={Path.Integrations}
              element={<Wrapper Target={IntegrationsScreen} />}
            />

            <Route
              path={Path.ZoomSettings}
              element={<Wrapper Target={ZoomSettingsScreen} />}
            />

            <Route
              path={Path.RedtailSettings}
              element={<Wrapper Target={RedtailSettings} />}
            />

            <Route
              path={Path.WealthboxSettings}
              element={<Wrapper Target={WealthboxSettings} />}
            />

            <Route
              path={Path.SalesforceSettings}
              element={<Wrapper Target={SalesforceSettingsScreen} />}
            />

            <Route
              path={Path.PractifiSettings}
              element={<Wrapper Target={PractifiSettingsScreen} />}
            />

            <Route
              path={Path.Xlr8Settings}
              element={<Wrapper Target={Xlr8SettingsScreen} />}
            />

            <Route
              path={Path.PaySuccess}
              element={<Wrapper Target={PaySuccessScreen} />}
            />

            <Route
              path={`${Path.ZoomCallback}`}
              element={<Wrapper Target={ZoomCallback} />}
            />

            <Route
              path={Path.ReceivedInvite}
              element={<Wrapper Target={ReceivedInviteScreen} />}
            />

            <Route
              path={Path.Templates}
              element={<Wrapper Target={TemplatesScreen} />}
            />

            <Route
              path={`${Path.AdminTranscriptJson}/:event_id`}
              element={<AdminTranscriptJson />}
            />

            <Route path={`${Path.PublicEvent}`} element={<PublicEventScreen />} />
            <Route path={`${Path.PublicEvent}/:id`} element={<PublicEventScreen />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  )
}

const sxContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  scrollbarGutter: 'stable',
  marginRight: '16px',
  overflow: 'overlay',
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
