export type Pagination = {
  pageIndex: number,
  pageSize: number
}

export enum SessionStorageEnum {
  pagination = "pagination",
}

export function getSessionStorage(key: SessionStorageEnum) {
  const value = sessionStorage.getItem(key)

  if (value == null)
    return null

  if (key == SessionStorageEnum.pagination)
    return JSON.parse(value) as Pagination

  return null
}

export function setSessionStorage(key: SessionStorageEnum, value: object) {
  const stringified = JSON.stringify(value)
  sessionStorage.setItem(key, stringified)
}