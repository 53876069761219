import { ActionItem, ClientDetail, WealthboxContactType, WealthboxProfile, WealthboxType, WealthboxVisibility } from "client"
import { logErr, logInfo } from "log"
import { Api, auth, config } from "service/api"
import { getErrorMessage } from "utils"
import queryString from 'query-string'

export async function wealthboxOAuth() {
  const windowName = 'PopupWindow'
  const windowFeatures = 'width=500,height=700'

  const queryParams = {
    client_id: config.wealthboxClientId,
    redirect_uri: config.wealthboxRedirect,
    response_type: "code",
    scope: "login data",
  }

  try {
    const baseUrl = `https://app.crmworkspace.com/oauth/authorize`
    const url = `${baseUrl}?${queryString.stringify(queryParams)}`
    window.open(url, windowName, windowFeatures)
  } catch (e) {
    logErr('wealthboxOAuth sign in')
  }
}

export async function saveWealthboxTokens(code: string): Promise<boolean | Error> {
  try {
    logInfo('wealthbox authenticate')
    const api = await Api()
    await api.wealthbox.authWealthBox(code)
    return true
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('wealthbox authenticate', { err })
    return err
  }
}

export async function getWealthboxProfile(): Promise<WealthboxProfile | null> {
  try {
    const api = await Api()
    const res = await api.wealthbox.profile()
    if (res == undefined)
      return null
    return res
  } catch (e) {
    if (e instanceof Error) {
      logErr('wealthbox profile', { e })
    }
  }
  return null
}

export async function getWealthboxContact(email?: string) {
  try {
    const api = await Api()
    return await api.wealthbox.getContact(email ?? auth.currentUser?.email ?? '')
  } catch (e) {
    logErr('wealthbox getContact', { e })
  }
}

export async function addWealthboxClientNote(
  client?: ClientDetail,
  eventId?: string,
  tasks?: ActionItem[],
  wbType?: WealthboxType,
  notes?: string,
  visibility: WealthboxVisibility = WealthboxVisibility.PRIVATE
): Promise<undefined | Error> {

  if (!client)
    return new Error('missing client')
  if (!eventId)
    return new Error('missing event Id')
  if (!tasks)
    return new Error('missing tasks')
  if (!wbType)
    return new Error('missing wbType')

  try {
    logInfo('Wealthbox Add Client Note', { client, eventId })
    const api = await Api()
    await api.wealthbox.addClientNoteWealthBox({
      event_id: eventId,
      notes,
      client,
      wb_type: wbType,
      tasks,
      visibility,
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox addClientNote', { err })
    return err
  }
}

export async function importWealthboxContacts(contactType: WealthboxContactType): Promise<number | Error> {
  try {
    logInfo('Wealthbox importContacts', { contactType })
    const api = await Api()
    return await api.wealthbox.importWealthboxContacts(contactType)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Wealthbox importContacts', { err })
    return err
  }
}

export async function disconnectWealthbox(): Promise<undefined | Error> {
  try {
    const api = await Api()
    await api.wealthbox.disconnectWealthBox()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('wealthbox disconnect', { err })
    return err
  }
}

export async function getWealthboxConfig() {
  try {
    const api = await Api()
    return await api.wealthbox.getWealthboxConfig()
  } catch (e) {
    logErr('wealthbox getConfig', { e })
  }
}

export async function setWealthboxVisibility(visibility: WealthboxVisibility) {
  try {
    const api = await Api()
    return await api.wealthbox.setVisibility(visibility)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('wealthbox set visibility', { err })
    return err
  }
}

export async function setWealthboxShowRecurringEvents(show: boolean) {
  try {
    const api = await Api()
    return await api.wealthbox.setShowRecurringCalEvents(show)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setWealthboxShowRecurringEvents', { err })
    return err
  }
}