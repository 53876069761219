import React, { ChangeEvent, useState } from 'react'
import { Typography, Box, CircularProgress, Switch } from '@mui/material'

import { useQueryClient } from '@tanstack/react-query'
import { CalendarEvents } from 'client'
import { setWealthboxShowRecurringEvents } from 'service/integrations/wealthboxApi'
import { gSx } from 'styles/Theme'

interface Props {
  cal?: CalendarEvents
}
export default function ShowWealthboxRecurringToggle({ cal }: Props) {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)

  async function onShowRecurringWealthbox(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    setBusy(true)
    const updateShow = !cal?.is_wealthbox_show_recurring
    await setWealthboxShowRecurringEvents(updateShow)
    await queryClient.invalidateQueries({ queryKey: ['getCalendarEvents'] })
    setBusy(false)
  }

  if (!cal?.is_wealthbox_signed_in)
    return null

  return (
    <Box sx={gSx.Row}>
      {busy
        ? <CircularProgress size={20} sx={{ marginX: 2.4 }} />
        : <Switch
          checked={cal?.is_wealthbox_show_recurring ?? true}
          onChange={onShowRecurringWealthbox}
          disabled={busy}
        />
      }
      <Typography>Show Wealthbox Recurring Events</Typography>
    </Box>
  )
}
