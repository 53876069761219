import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { SxProps, Card, CardHeader, Container, Tab, Typography, CardContent } from '@mui/material'
import OrgName from 'components/settings/OrgName'
import OrgUsers from 'components/settings/OrgUsers'
import OrgDataRetention from 'components/settings/OrgDataRetention'
import StripeCustomerInfo from 'components/pay/StripeCustomerInfo'
import { useQuery } from '@tanstack/react-query'
import { getOrg } from 'service/orgsApi'
import { logInfo } from 'log'
import { gSx } from 'styles/Theme'
import OrgInviteCreate from 'components/settings/OrgInviteCreate'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import InviteStatus from 'components/settings/InviteStatus'
import StripeSubscriptionRemaining from 'components/pay/StripeSubscriptionRemaining'
import { getSubscription } from 'service/payApi'
import Grid from "@mui/material/Unstable_Grid2/Grid2"

export default function OrgScreen() {
  const [tabValue, setTabValue] = useState("1")

  useEffect(() => {
    logInfo('Organization Screen')
  }, [])

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const { data: org, isLoading: isLoadingOrg } = useQuery({
    queryKey: ['getOrg'],
    queryFn: async () => await getOrg(),
  })

  const { data: sub, isLoading: isLoadingSub } = useQuery({
    queryKey: ['getSubscription'],
    queryFn: getSubscription,
  })
  const isLoading = isLoadingOrg || isLoadingSub

  return (
    <Container>
      <Box sx={gSx.RowBetween}>
        <Typography variant='h1' sx={{ paddingY: '3vh' }} >Organization</Typography>
        {org && <OrgInviteCreate org={org} />}
      </Box>

      <OrgName />

      <Grid container alignItems="center" spacing={2}>
        <Grid xs={12} md={6}>
          <Card sx={{ height: 60 }}>
            <StripeCustomerInfo org={org} />
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card sx={{ height: 60 }}>
            <StripeSubscriptionRemaining org={org} sub={sub} isLoading={isLoading} />
          </Card>
        </Grid>
      </Grid>

      <Card>
        <TabContext value={tabValue}>
          <TabList sx={sxTabList} onChange={onTabChange}>
            <Tab
              sx={sxTabList}
              label='Members'
              value='1'
            />
            <Tab
              sx={sxTabList}
              label='Invites'
              value='2'
            />
          </TabList>
          <TabPanel sx={sxTabPanel} value='1'>
            <OrgUsers org={org} sub={sub} isLoadingParent={isLoading} />
          </TabPanel>
          <TabPanel sx={sxTabPanel} value='2'>
            <InviteStatus />
          </TabPanel>
        </TabContext>
      </Card>

      <Card sx={{ margin: 0 }}>
        <CardHeader sx={{ padding: 0.3 }} title={<Typography variant='h6'>Data Retention</Typography>} subheader={<><Typography variant='body2'>The data retention policy impacts all organization members and can be adjusted by the admin.</Typography><Typography variant='body2'>Please note: selecting &apos;No Retention&apos; for transcript will disable Analytics and speaker name updates, which could affect summarization results.</Typography></>}></CardHeader>
        <CardContent>
          <OrgDataRetention />
        </CardContent>

      </Card>
    </Container >
  )
}

const sxTabList: SxProps = {
  minHeight: '30px',
}

const sxTabPanel: SxProps = {
  padding: '0',
}