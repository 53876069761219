/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDetail } from '../models/ClientDetail';
import type { ClientIndex } from '../models/ClientIndex';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Clients Index
     * @returns ClientIndex Successful Response
     * @throws ApiError
     */
    public getClientsIndex(): CancelablePromise<Array<ClientIndex>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/index',
        });
    }

    /**
     * Get Client Detail
     * @param clientId
     * @returns ClientDetail Successful Response
     * @throws ApiError
     */
    public getClientDetail(
        clientId: string,
    ): CancelablePromise<ClientDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/detail',
            query: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert Client
     * @param requestBody
     * @param eventId
     * @returns ClientDetail Successful Response
     * @throws ApiError
     */
    public upsertClient(
        requestBody: ClientDetail,
        eventId?: string,
    ): CancelablePromise<ClientDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/upsert',
            query: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Client
     * @param clientId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteClient(
        clientId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/clients/delete',
            query: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
