import React from 'react'
import { Typography, Box, Card, SxProps, Link, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getCalendarEvents } from '../../service/calendarApi'
import { CalendarEvent } from '../../client'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Path } from '../../routes'
import { useNavigate } from 'react-router-dom'
import SignInCalendarButton from './SignInCalendarButton'
import { CalendarShortSkeletons, CalendarSkeletons } from 'components/Skeletons'
import CalendarEventSimple from './CalendarEventSimple'
import ShowWealthboxRecurringToggle from './ShowWealthboxRecurringToggle'
import CalendarConnectOptions from './CalendarConnectOptions'

export default function ListCalendarEvents() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getCalendarEvents'], queryFn: getCalendarEvents
  })
  const anyCalConnected = (data?.is_recall_cal_signed_in || data?.is_redtail_signed_in || data?.is_wealthbox_signed_in) ?? false

  const short = Array.isArray(data?.events) ? data.events.slice(0, 3) : undefined

  if (isLoading)
    return (
      <CalendarSkeletons />
    )

  return (
    <Card>
      <Typography variant='h3'>Next Calendar Events</Typography>

      {
        isLoading || !short
          ? <CalendarShortSkeletons />
          : anyCalConnected
            ? (
              short.length === 0 ? (
                <Typography sx={{ mt: 5 }}>No upcoming events</Typography>
              ) : (
                <>
                  <ShowWealthboxRecurringToggle cal={data} />
                  <Box sx={{ padding: '1vh' }} />
                  {
                    short?.map(event => {
                      const date = new Date(event.start_time ?? '')
                      const readable = date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })
                      return (
                        <Box key={event.id}>
                          {
                            ShowDateIfEarliest(date, short) &&
                            <Box sx={sxDate}>
                              <CalendarTodayIcon />
                              <Box sx={{ paddingX: '1vh' }} />
                              <Typography>{readable}</Typography>
                            </Box>
                          }
                          <CalendarEventSimple event={event} isSmall />
                        </Box>
                      )
                    })
                  }
                  <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(Path.Calendar)}>See More</Link>
                </>

              )
            )
            : <CalendarConnectOptions />
      }

    </Card>
  )
}

const sxDate: SxProps = {
  paddingX: '2vh',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

function ShowDateIfEarliest(date: Date, all: CalendarEvent[]) {
  const meetingsOftheDay = all.filter(m => (new Date(m.start_time ?? '')).toLocaleDateString() === date.toLocaleDateString())
  for (const m of meetingsOftheDay) {
    if (new Date(m.start_time ?? '') < date)
      return false
  }
  return true
}
