import React, { useEffect } from 'react'
import { Typography, Box, ButtonBase, SxProps, Button } from '@mui/material'
import { CalendarEvents } from 'client'
import { isFirebaseUserGoogle } from 'utils'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { Media } from 'service/media'
import { gColor, gSx } from 'styles/Theme'
import SignInCalendarButton from './SignInCalendarButton'

export default function CalendarConnectOptions() {
  // const navigate = useNavigate()
  // const isGoogle = isFirebaseUserGoogle()

  return (
    <>
      <Box sx={{ padding: 1 }} />
      <SignInCalendarButton />
    </>
  )

  // not used until redtail can do scheduled recording

  // return (
  //   <Box sx={gSx.CenterVertical}>
  //     <Box sx={{ padding: 1 }} />

  //     <Typography>
  //       Sign into any one of these options to connect your calendar
  //     </Typography>
  //     <Box sx={{ padding: 1 }} />

  //     <Button
  //       variant='contained'
  //       onClick={() => navigate(Path.CalendarAuth)}
  //       aria-label={'calendar'}
  //       sx={sxButton}
  //     >
  //       <Box
  //         component="img"
  //         sx={icon}
  //         src={isGoogle ? Media.googleCalendar : Media.microsoft100}
  //         alt="Redtail"
  //       />
  //       <Typography>{isGoogle ? "Connect Google Calendar" : "Connect Outlook Calendar"}</Typography>
  //     </Button>

  //     <Box sx={{ padding: 1 }} />

  //     <Button
  //       variant='contained'
  //       onClick={() => navigate(Path.RedtailSettings)}
  //       aria-label={'redtail'}
  //       sx={sxButton}
  //     >
  //       <Box
  //         component="img"
  //         sx={icon}
  //         src={Media.redtailIconWhite}
  //         alt="Redtail"
  //       />
  //       <Typography>Connect Redtail</Typography>
  //     </Button>

  //     <Box sx={{ padding: 1 }} />

  //     # TODO. Asking wealthbox if theres a better way to handle recurring events.
  //     # https://app.clickup.com/t/8689a2fur
  //     <Button
  //       variant='contained'
  //       onClick={() => navigate(Path.WealthboxSettings)}
  //       aria-label={'wealthbox'}
  //       sx={sxButton}
  //     >
  //       <Box
  //         component="img"
  //         sx={icon}
  //         src={Media.wealthboxIcon}
  //         alt="Wealthbox"
  //       />
  //       <Typography>Connect Wealthbox</Typography>
  //     </Button>

  //   </Box>
  // )
}
const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: '5px'
}

const sxButton: SxProps = {
  backgroundColor: 'white',
  color: gColor.green500,
  width: 300
}