import React, { useEffect, useState } from 'react'
import { Box, SxProps, Card, Typography, Switch, Snackbar, Alert, Divider, Link } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUserConfig, updateEmailSettings, UserConfigKey } from 'service'
import { gSx } from 'styles/Theme'
import { SkeletonRows } from 'components/Skeletons'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'

export default function EmailSettingsCard() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'],
    queryFn: getUserConfig,
  })

  async function onUpdateEmailSettings(key: UserConfigKey, checked: boolean) {
    setErr(undefined)
    setBusy(true)
    const res = await updateEmailSettings(key, checked)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  return (
    <Card>
      <Typography variant='h4'>Email Settings</Typography>

      <Box sx={{ paddingY: 1 }} />

      {
        isLoading
          ? <SkeletonRows count={4} height={65} />
          : <>
            <Box sx={gSx.Row}>
              <Switch
                checked={data?.email_transcript_done}
                onChange={(event, checked) => onUpdateEmailSettings(UserConfigKey.EmailTranscriptDone, checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={busy}
              />
              <Box>
                <Typography variant='h6'>Meeting Transcript Ready Email</Typography>
                <Typography>Receive an email notification when the transcript has been processed and is ready for you to choose the meeting summary type.</Typography>
              </Box>
            </Box>

            <Box sx={{ paddingY: 1 }} />

            <Box sx={gSx.Row}>
              <Switch
                checked={data?.email_summary_done}
                onChange={(event, checked) => onUpdateEmailSettings(UserConfigKey.EmailSummaryDone, checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={busy}
              />
              <Box>
                <Box>
                  <Typography variant='h6'>Meeting Summary Finished Email</Typography>
                  <Typography>Get an email notification once the meeting summary has finished processing and is available for review.</Typography>
                </Box>
              </Box>
            </Box>
          </>
      }

      <Box sx={{ paddingY: 1 }} />

      {err && <Typography sx={{ color: 'tomato' }}>{err}</Typography>}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Email Settings Updated
        </Alert>

      </Snackbar>
    </Card >
  )
}
