import React, { useEffect } from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { gSx } from 'styles/Theme'
import { Region } from 'client'
import { Media } from 'service/media'
import { useQuery } from '@tanstack/react-query'
import { getUser } from 'service'

export default function RegionBadge() {

  const { data: user } = useQuery({
    queryKey: ['getUser'],
    queryFn: async () => await getUser(),
    enabled: false
  })

  if (user?.region == Region.CANADA)
    return (
      <Box sx={gSx.Row}>
        <Box
          component="img"
          src={Media.canada_flag}
          sx={{ width: 25, paddingBottom: 0.4 }}
          aria-label='Canada Region'
        />
        <Box sx={{ padding: 0.5 }} />
        <Typography variant='caption'>CANADA</Typography>
      </Box>
    )

  return <Box sx={{ padding: 1 }} />
}
