import { ActionItem, AssigneeInfo, ClientDetail, RedtailBase, RedtailTeam, RedtailUser, RedtailVisibility } from "client"
import { logErr, logInfo } from "log"
import { Api, auth } from "../api"
import { getErrorMessage } from "utils"

export async function getRedtailSettings(): Promise<RedtailBase | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    return await api.redtail.getRedtailSettings()
  } catch (e) {
    logErr('getRedtailSettings', { e })
  }
}

export async function authenticate(user: string, password: string): Promise<Error | undefined> {
  if (!auth.currentUser)
    return new Error("missing auth.currentUser")
  try {
    logInfo('Redtail Authenticate', { user })
    const api = await Api()
    await api.redtail.authenticate({ user, password })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Redtail Authenticate', { err })
    return err
  }
}

export async function getRedtailUsers() {
  try {
    const api = await Api()
    return await api.redtail.getRedtailUsers()
  } catch (e) {
    logErr('getRedtailUsers', { e })
  }
}

export async function getRedtailUserDb(name?: string) {
  if (!name)
    return
  try {
    const api = await Api()
    return await api.redtail.getRedtailUserDb(name)
  } catch (e) {
    logErr('getRedtailUserDb', { e })
  }
}

export async function setRedtailUserNotify(user?: RedtailUser) {
  if (!user)
    user = {}
  try {
    const api = await Api()
    return await api.redtail.setUserNotify(user)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setRedtailUserNotify', { err })
    return err
  }
}

export async function getRedtailTeams() {
  try {
    const api = await Api()
    return await api.redtail.getTeams()
  } catch (e) {
    logErr('getRedtailTeams', { e })
  }
}

export async function setRedtailTeamNotify(team?: RedtailTeam) {
  if (!team)
    team = {}
  try {
    const api = await Api()
    return await api.redtail.setTeamNotify(team)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setRedtailTeamNotify', { err })
    return err
  }
}

export async function setRedtailVisibility(visibility: RedtailVisibility) {
  try {
    const api = await Api()
    return await api.redtail.setRedtailVisibility(visibility)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('redtail set visibility', { err })
    return err
  }
}

export async function importRedtailContacts(): Promise<number | Error> {
  try {
    logInfo('Redtail importContacts')
    const api = await Api()
    return await api.redtail.importRedtailContacts()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Redtail importContacts', { err })
    return err
  }
}

export async function addRedtailClientNote(client?: ClientDetail, eventId?: string, tasks?: ActionItem[], visibility?: RedtailVisibility, notes?: string): Promise<Error | undefined> {

  if (!client)
    return new Error('missing client')
  if (!eventId)
    return new Error('missing event Id')
  if (!tasks)
    return new Error('missing tasks')
  if (!visibility)
    return new Error('missing visibility')

  try {
    logInfo('Redtail Add Client Note', { client, eventId })
    const api = await Api()

    await api.redtail.addClientNote({
      event_id: eventId,
      notes,
      client,
      tasks,
      visibility
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('redtail addClientNote', { err })
    return err
  }
}

export async function disconnect(): Promise<Error | undefined> {
  try {
    logInfo('Redtail Sign out Pressed')
    const api = await Api()
    await api.redtail.disconnect()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('redtail disconnect', { err })
    return err
  }
}
