import React, { useEffect, useState } from 'react'
import { Typography, Snackbar, Alert } from '@mui/material'
import 'firebase/auth'
import { LoadingButton } from '@mui/lab'
import { useQueryClient } from '@tanstack/react-query'
import { importRedtailContacts } from 'service/integrations/redtailApi'

export default function RedtailImportContacts() {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [numImported, setNumImported] = useState<number | undefined>()

  async function onImportContacts() {
    setErr(undefined)
    setBusy(true)
    const res = await importRedtailContacts()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      setNumImported(res)
      await queryClient.invalidateQueries({ queryKey: ['getClientsIndex'] })
    }
    setBusy(false)
  }

  return (
    <>
      <LoadingButton
        onClick={onImportContacts}
        loading={busy}
        variant='outlined'
      >
        Import Redtail Contacts
      </LoadingButton>

      {
        err && <Typography sx={{ color: 'tomato' }}>
          {err}
        </Typography>
      }

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {numImported} Redtail Contacts Synced Successfully
        </Alert>
      </Snackbar>
    </>
  )
}