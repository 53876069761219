import React, { useEffect, useState } from 'react'
import { Link, Card, Box, SxProps, Typography, Grid, LinearProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Path } from 'routes'
import GoogleSignInButton from 'components/auth/GoogleSignInButton'
import MicrosoftSignIn from 'components/auth/MicrosoftSignInButton'
import { Media } from 'service/media'
import {
  firebaseGoogleAuth,
  firebaseMicrosoftAuth,
} from 'service/authApi'
import { logInfo } from 'log'
import { useQuery } from '@tanstack/react-query'
import { getAffiliateUrlShortener } from 'service/publicApi'
import { auth, config } from 'service/api'
import ManualSignUp from 'components/auth/ManualSignup'
import { getRegionFromSubDomain } from 'service/host'
import { Region } from 'client'
import { gSx } from 'styles/Theme'

export default function SignupAffiliation() {
  const { id } = useParams()
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const [busy, setBusy] = useState(false)
  const currentParams = new URLSearchParams(location.search)
  const [resParams, setResParams] = useState<string | undefined>()
  const affiliateId = `affiliateid=${id}` // affiliate_url_shortener collection, doc id.
  const loadingUrl = `${Path.Loading}?${resParams}&${affiliateId}`
  const [err, setErr] = useState<string | undefined>()
  const region = currentParams.get('region')
  const regionFromSubDomain = getRegionFromSubDomain()

  const { data, isLoading } = useQuery({
    queryKey: ['getAffiliateUrlShortener', id],
    queryFn: async () => await getAffiliateUrlShortener(id),
  })
  useEffect(() => {
    if (regionFromSubDomain) {
      window.location.href = `${config.web}/hi/${id}?region=${regionFromSubDomain}`
      return
    }

    if (fbUser)
      navigate(Path.Loading)
  }, [fbUser])

  useEffect(() => {
    if (data) {
      logInfo(`Signup Affiliation Screen`, { data })
      const params = data.offer_url.split('?')[1]
      setResParams(params)
    } else if (!isLoading && !data) {
      logInfo(`Signup Affiliation, no Affiliation. Redirect`, { id })
      navigate(`${Path.SignUp}?region=${regionFromSubDomain}`)
    }
  }, [data, isLoading])

  async function signInGoogle() {
    setBusy(true)
    setErr(undefined)
    const res = await firebaseGoogleAuth()
    res instanceof Error
      ? setErr(res.message)
      : navigate(loadingUrl)
    setBusy(false)
  }

  async function signInMicrosoft() {
    setBusy(true)
    setErr(undefined)
    const res = await firebaseMicrosoftAuth()
    res instanceof Error
      ? setErr(res.message)
      : navigate(loadingUrl)

    setBusy(false)
  }

  if (isLoading) {
    return (
      <Box sx={sxPage}>
        <Box component='img' sx={sxLogo} src={Media.logo100} />
        <Box sx={{ width: '30%', paddingTop: 5 }}>
          <LinearProgress />
        </Box>
      </Box >
    )
  }

  return (
    <Box sx={sxPage}>
      <Box sx={sxRow} onClick={() => window.open('https://finmate.ai', '_blank')}>
        <Box component='img' alt='Finmate AI logo' sx={sxIcon} src={Media.logo100} />
        <Typography variant='h1'>FinMate AI</Typography>
      </Box>

      {
        region == Region.CANADA &&
        <Box sx={gSx.Row}>
          <Box
            component="img"
            src={Media.canada_flag}
            sx={{ width: 30 }}
          />
          <Box sx={{ padding: 1 }} />
          <Typography>Canada</Typography>
        </Box>
      }

      <Box sx={{ padding: '2vh' }} />

      <Card sx={sxCard}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ alignContent: 'center' }}>
            <Box sx={sxLeft}>

              {data?.target_company_logo_url &&
                <Box component='img' alt='Finmate AI logo' sx={sxCompanyLogo} src={data?.target_company_logo_url} />
              }
              <Box sx={{ padding: '2vh' }} />

              <Typography variant='h1' sx={{ alignSelf: 'center' }}>Welcome {data?.target_company}</Typography>
              <Box sx={{ padding: '2vh' }} />

              <Typography>
                {data?.message
                  ? data.message
                  : `Ready to never take notes again? If you have any questions, we're here to help.`
                }
              </Typography>
              <Box sx={{ padding: '1vh' }} />

              <Typography>-{data?.owner_display_name}</Typography>
              <Link href={`mailto:${data?.owner_email}`} color="inherit"><Typography>{data?.owner_email}</Typography></Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ alignContent: 'center', backgroundColor: '#ACD5EB' }}>
            <Box sx={sxRight}>

              <Box sx={sxButtonWrapper}>
                <GoogleSignInButton
                  text={`Sign up With Google`}
                  disable={busy}
                  onClick={signInGoogle}
                />
              </Box>

              <Box sx={sxButtonWrapper}>
                <MicrosoftSignIn
                  text={`Sign up With Microsoft`}
                  disable={busy}
                  onClick={signInMicrosoft}
                />
              </Box>

              <Box sx={{ padding: '0.5vh' }} />
              {err && <Typography color={'tomato'}>{err}</Typography>}
              <Box sx={{ padding: '0.5vh' }} />

              <Typography variant='caption'>
                By Using FinMate AI, you agree to the{' '}
                <Link rel='noopener' target='_blank' href={'https://www.finmate.ai/terms'}>
                  Terms of Service
                </Link>{', '}

                <Link rel='noopener' target='_blank' href='https://www.finmate.ai/privacy'>
                  Privacy Policy
                </Link>
                {', and '}
                <Link rel='noopener' target='_blank' href="https://finmate.ai/google">AI Limited Use Requirements</Link>
                .
                <br />
                {/* {`FinMate AI's use and transfer to any other app of information received from Google APIs will adhere to `}
                <Link rel='noopener' target='_blank' href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</Link>
                {`, including the Limited Use requirements. `} */}

              </Typography>

            </Box>
          </Grid>

        </Grid>
      </Card>

      <ManualSignUp
        loadingPath={loadingUrl}
      />

    </Box >
  )
}

const sxCard: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  maxWidth: '900px'
}

const sxRight: SxProps = {
  padding: '2vh',
}

const sxLeft: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  marginY: '3vh',
  padding: '2vh',
}

const sxButtonWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  margin: '3vh',
}

const sxRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}

const sxIcon: SxProps = {
  width: 33,
  height: 50,
  marginRight: 2,
}

const sxPage: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',
}

const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  alignContent: 'center',
  paddingLeft: '5px',
  paddingRight: '10px',
}

const sxCompanyLogo: SxProps = {
  width: 150,
  alignSelf: 'center',
}