import { Region } from "client"

export function getRegionFromSubDomain() {
  const url = window.location.href
  let region = undefined

  if (url.includes('canada.localhost')
    || url.includes('canada-dev.finmate')
    || url.includes('canada.finmate'))
    region = Region.CANADA

  return region
}
