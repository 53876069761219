import { ClientIndex } from 'client'
import Fuse from 'fuse.js'

export function createFuzzySearch(clients?: ClientIndex[]) {
  if (!clients)
    return
  // Combine first and last name into a fullName field
  const peopleWithFullName = clients.map(person => ({
    ...person,
    fullName: `${person.first_name} ${person.last_name}`,
  }))

  const options = {
    keys: ['firstName', 'lastName', 'fullName', 'email'],
    threshold: 0.3,
  }

  const fuse = new Fuse(peopleWithFullName, options)

  return (query: string) => {
    if (!query) return clients
    return fuse.search(query).map(result => result.item)
  }
}