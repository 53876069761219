import React, { useEffect, useRef, useState } from 'react'
import { Box, SxProps, Typography, Button, TextField } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../service/api'
import { Path } from '../../routes'
import { signOut } from 'firebase/auth'
import TransitionWrapper from 'components/transition/TransitionWrapper'
import { gSx } from 'styles/Theme'
import { getUser, updateSignupInfo } from 'service'
import { LoadingButton } from '@mui/lab'
import { Media } from 'service/media'
import { useQuery } from '@tanstack/react-query'
import RegionBadge from 'components/auth/RegionBadge'

const BlockedPendingDemoScreen = () => {
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const [item, setItem] = React.useState(1)
  const [companyName, setCompanyName] = useState('')
  const [aum, setAUM] = useState('')
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)

  const { data: user } = useQuery({
    queryKey: ['getUser'],
    queryFn: async () => await getUser(),
  })
  const alreadySubmitted = !!user?.signup_info

  useEffect(() => {
    if (alreadySubmitted)
      setItem(3)
  }, [alreadySubmitted])

  const onKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      onNext()
    }
  }
  const onNext = async () => {
    setItem(item + 1)
  }
  const onBack = async () => {
    setItem(item - 1)
  }

  async function onSubmit() {
    setBusy(true)
    setErr(undefined)
    const res = await updateSignupInfo({
      company_name: companyName,
      aum: aum,
    })
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setItem(item + 1)
    }
    setBusy(false)
  }

  async function onSignout() {
    await signOut(auth)
    navigate(Path.SignIn)
  }

  if (fbUser === null) {
    navigate(Path.SignIn)
  }

  return (
    <Box>
      <Box sx={gSx.RowBetween}>
        <Box sx={gSx.Row}>
          <Box component='img'
            aria-label='Finmate AI logo'
            alt='Finmate AI logo'
            sx={sxLogo}
            src={Media.logo100}
          />
          <Typography sx={sxTitle}>
            FinMate AI
          </Typography>
        </Box>
        <RegionBadge />
      </Box>

      <Box sx={center}>
        <TransitionWrapper active={item === 1}>
          <Typography variant={'h1'}>Welcome!</Typography>
          <Box sx={{ padding: 1 }} />

          <Typography>
            {`We noticed you signed up with a personal email. For immediate access to a free trial, please sign up using your work email.`}
          </Typography>

          <Box sx={{ padding: 1 }} />

          <Typography variant='h4'>
            {`Otherwise, please enter your firm's name below:`}
          </Typography>
          <Box sx={{ padding: 2 }} />
          <TextField
            autoFocus
            id='companyName'
            label='Firm Name'
            value={companyName}
            onChange={(t) => setCompanyName(t.target.value)}
            onKeyDown={onKeyPress}
            sx={sxInput}
          />

          <Button
            onClick={onNext}
            sx={sxNext}
            variant='contained'
            disabled={companyName.length == 0}
          >
            Next
          </Button>
        </TransitionWrapper>

        <TransitionWrapper active={item === 2}>
          <Typography variant={'h4'}>
            What is your total AUM?
          </Typography>
          <Box sx={{ padding: 2 }} />
          <TextField
            autoFocus
            id='aum'
            label="AUM Amount"
            value={aum}
            onChange={(t) => setAUM(t.target.value)}
            onKeyDown={onKeyPress}
            sx={sxInput}
          />
          <Box sx={gSx.RowBetween}>
            <Button onClick={onBack} sx={sxBack}>Back</Button>
            <LoadingButton
              onClick={onSubmit}
              sx={sxNext}
              variant='contained'
              loading={busy}
              disabled={busy || aum.length == 0}
            >
              Submit
            </LoadingButton>
          </Box>

          {err && <Typography color={'tomato'} sx={{ padding: 2 }}>
            {err}
          </Typography>
          }
        </TransitionWrapper>

        <TransitionWrapper active={item === 3}>
          <Typography variant={'h1'}>Thank you</Typography>
          <Typography variant={'h1'}>We will reach out soon</Typography>
          <Box sx={{ padding: 2 }} />
          <Typography>{`Can't wait? Book a demo with us now.`}</Typography>
          <Box sx={{ padding: 1 }} />
          <Link to="https://zcal.co/danielfinmate/finmate-intro">
            <Button variant="contained" color="primary">
              Book a Demo
            </Button>
          </Link>
        </TransitionWrapper>
      </Box >
      <Button onClick={onSignout}>Signout</Button>
    </Box>
  )
}
export default BlockedPendingDemoScreen

const center: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh'
}

const sxBack: SxProps = {
  alignSelf: 'flex-start',
  marginTop: 2,
}

const sxNext: SxProps = {
  alignSelf: 'flex-end',
  marginTop: 2,
  paddingX: 3
}

const sxInput: SxProps = {
}

const sxTitle: SxProps = {
  fontSize: 23,
  fontWeight: 400,
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'primary.main'
}
const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  alignContent: 'center',
  paddingRight: '14px',
}