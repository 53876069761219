import React, { useEffect, useState } from 'react'
import { Typography, Snackbar, Alert, Button, Box, Card, FormControl, Select, MenuItem } from '@mui/material'
import 'firebase/auth'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { importWealthboxContacts } from 'service/integrations/wealthboxApi'
import { useQueryClient } from '@tanstack/react-query'
import { WealthboxContactType } from 'client'
import { gSx } from 'styles/Theme'

export default function WealthboxImportContacts() {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [numImported, setNumImported] = useState<number | undefined>()

  const [contactType, setContactType] = useState<WealthboxContactType>(WealthboxContactType.CLIENT)

  async function onImportContacts() {
    setErr(undefined)
    setBusy(true)
    const res = await importWealthboxContacts(contactType)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      setNumImported(res)
      await queryClient.invalidateQueries({ queryKey: ['getClientsIndex'] })
    }
    setBusy(false)
  }

  return (
    <Card>
      <Typography variant='h4'>Import Wealthbox Contacts</Typography>
      <Box sx={{ padding: 1 }} />

      <Box sx={gSx.RowBetween}>
        <FormControl sx={{ width: 150 }}>
          <Select
            id="contact_type"
            value={contactType ? contactType : 'All'}
            onChange={e => setContactType(e.target.value as WealthboxContactType)}
            disabled={busy}
          >
            <MenuItem value={WealthboxContactType.ALL}>All</MenuItem>
            <MenuItem value={WealthboxContactType.CLIENT}>Client</MenuItem>
            <MenuItem value={WealthboxContactType.PAST_CLIENT}>Past Client</MenuItem>
            <MenuItem value={WealthboxContactType.PROSPECT}>Prospect</MenuItem>
          </Select>
        </FormControl>

        <LoadingButton
          onClick={onImportContacts}
          loading={busy}
          variant='outlined'
        >
          Import
        </LoadingButton>
      </Box>

      <Typography variant='caption'>Only contacts with emails are imported.</Typography>

      <Box sx={{ padding: 1 }} />

      {contactType == WealthboxContactType.ALL && <Typography>
        {` By Selecting "All", you are including clients, past clients, prospectives, vendors, organizations, and all unassigned contact types.`}
      </Typography>
      }

      {err && <Typography sx={{ color: 'tomato' }}>{err}</Typography>}

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {numImported} Wealthbox Contacts Synced Successfully
        </Alert>
      </Snackbar>
    </Card>
  )
}