import React, { useState } from 'react'
import { Typography, Box, Card, Switch, Link, Tooltip, Snackbar, Alert, SxProps } from '@mui/material'
import { CalendarEvent } from '../../client'
import { gSx, gSxText } from '../../styles/Theme'
import { UpdateCalendarMeeting } from '../../service/calendarApi'
import Grid from '@mui/material/Unstable_Grid2'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import CheckIcon from '@mui/icons-material/Check'
import { Media } from 'service/media'

interface CalEventProp {
  event: CalendarEvent
  isSmall?: boolean
}

export default function CalendarEventSimple({ event, isSmall }: CalEventProp) {
  const time = (new Date(event.start_time ?? '')).toLocaleTimeString()
  const [record, setRecord] = useState(event.will_record)
  const { isBlocked } = useIsBlockedByPlan()
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  async function onRecord(switchElement: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setErr(undefined)
    setRecord(checked)
    const res = await UpdateCalendarMeeting(event.id, checked)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
    }
  }
  const url = event.meeting_url
  const disableRecord = isBlocked || event.is_redtail || event.is_wealthbox || !url || url?.length == 0

  return (
    <Card>
      <Grid container>
        <Grid xs={isSmall ? 8 : 12} md={isSmall ? 8 : 6}>
          <Typography variant='h6'>{event.title}</Typography>
          <Typography>{time}</Typography>
          <Link rel="noopener" target="_blank" href={url}>{url}</Link>
        </Grid>

        <Grid xs={isSmall ? 4 : 12} md={4}>
          <Typography variant='h6'>Attendees</Typography>
          {event.attendees?.map(a =>
            <Typography key={a.email}>{a.email}{a.is_organizer && ' (host)'}</Typography>
          )}
        </Grid>

        <Grid xs={12} md={isSmall ? 12 : 2} sx={sxRightColumn}>

          <Box>
            <Tooltip title={isBlocked ? 'Requires Starter or Standard Plan' : ""}>
              <Tooltip
                title={event.is_redtail || event.is_wealthbox
                  ? <span style={{ whiteSpace: 'pre-line' }}>{`Coming Soon. \n For now, you can manually have FinMate join your meetings.`}</span>
                  : ""
                }
              >
                <Box sx={gSx.Row}>
                  <Switch
                    checked={record}
                    onChange={onRecord}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={disableRecord}
                  />
                  <Typography sx={gSxText.small}>Record</Typography>
                </Box>
              </Tooltip>
            </Tooltip>

          </Box>
          <Box sx={sxBadge}>
            {event.is_redtail &&
              <Tooltip title="Redtail Calendar">
                <Box
                  component="img"
                  sx={sxIcon}
                  src={Media.redtailIconWhite}
                  alt="Redtail"
                />
              </Tooltip>
            }
            {event.is_wealthbox &&
              <Tooltip title="Wealthbox Calendar">
                <Box
                  component="img"
                  sx={sxIcon}
                  src={Media.wealthboxIcon}
                  alt="Wealthbox"
                />
              </Tooltip>
            }
          </Box>

          {err && <Typography color={'tomato'} >{err}</Typography>}
        </Grid>
      </Grid>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Record Preference Updated Succesfully
        </Alert>
      </Snackbar>
    </Card>
  )
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
}

const sxRightColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

const sxBadge: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}