import React from 'react'
import { Box, Tooltip, SxProps } from '@mui/material'
import { ClientDetail, ClientIndex, SalesforceApp } from 'client'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'
import { Media } from 'service/media'
import { useQuery } from '@tanstack/react-query'

interface Props {
  client?: ClientIndex | ClientDetail
}

export function CrmClientBadge({ client }: Props) {

  const { data: salesforceSettings } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings, enabled: false,
  })
  const salesforceApp = salesforceSettings?.app ?? SalesforceApp.SALESFORCE

  if (!client)
    return

  return (
    <Box>
      {
        client.redtail_id &&
        <Tooltip title='Redtail Contact'>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.redtailIconWhite}
            alt="Redtail"
          />
        </Tooltip>
      }

      {
        client.wealthbox_id &&
        <Tooltip title='Wealthbox Contact'>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.wealthboxIcon}
            alt="Wealthbox"
          />
        </Tooltip>
      }

      {
        client.salesforce_id &&
        <Tooltip title={`${salesforceApp} Contact`}>
          <Box
            component="img"
            sx={sxIcon}
            src={getSalesforceIcon(salesforceApp)}
            alt="Salesforce"
          />
        </Tooltip>
      }
    </Box>
  )
}

const sxIcon: SxProps = {
  marginLeft: 1,
  width: 25,
  height: 25,
}