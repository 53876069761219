/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_addClientNote } from '../models/Body_addClientNote';
import type { Body_authenticate } from '../models/Body_authenticate';
import type { RedtailBase } from '../models/RedtailBase';
import type { RedtailTeam } from '../models/RedtailTeam';
import type { RedtailUser } from '../models/RedtailUser';
import type { RedtailVisibility } from '../models/RedtailVisibility';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RedtailService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Authenticate
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public authenticate(
        requestBody: Body_authenticate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/authenticate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Redtail
     * @returns RedtailBase Successful Response
     * @throws ApiError
     */
    public getRedtailSettings(): CancelablePromise<RedtailBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/',
        });
    }

    /**
     * Disconnect
     * @returns any Successful Response
     * @throws ApiError
     */
    public disconnect(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/redtail/',
        });
    }

    /**
     * Import Contacts
     * @returns number Successful Response
     * @throws ApiError
     */
    public importRedtailContacts(): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/contacts/import',
        });
    }

    /**
     * Add Client Note
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public addClientNote(
        requestBody: Body_addClientNote,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * @returns RedtailUser Successful Response
     * @throws ApiError
     */
    public getRedtailUsers(): CancelablePromise<Array<RedtailUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/users',
        });
    }

    /**
     * Get User Db
     * @param name
     * @returns RedtailUser Successful Response
     * @throws ApiError
     */
    public getRedtailUserDb(
        name: string,
    ): CancelablePromise<RedtailUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/user',
            query: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Teams
     * @returns RedtailTeam Successful Response
     * @throws ApiError
     */
    public getTeams(): CancelablePromise<Array<RedtailTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/redtail/teams',
        });
    }

    /**
     * Set User Notify
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public setUserNotify(
        requestBody: RedtailUser,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/users/notify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Default Team Notify
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public setTeamNotify(
        requestBody: RedtailTeam,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/teams/notify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Setvisibility
     * @param visibility
     * @returns any Successful Response
     * @throws ApiError
     */
    public setRedtailVisibility(
        visibility: RedtailVisibility,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/redtail/visibility',
            query: {
                'visibility': visibility,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
